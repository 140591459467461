@import './colors.scss';
@import './fonts.scss';

.prompt-modal {
    width: calc(100vw - 31px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white);
    border-radius: 10px;
    padding: 78px 16px 10px;
    text-align: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        opacity: 1;

        &:hover {
            &::before,
            &::after {
                opacity: .5;
                transition: .1s;
            }
        }
    }

    &__iconExclamationPoint {
        width: 72px;
        height: 72px;
        margin-bottom: 25px;
    }

    &__title {
        margin-bottom: 20px;
        font-family: IBMPlexSans;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
        padding: 0 16px;
    }

    &__subtitle {
        margin-bottom: 0.5rem;
        font-family: IBMPlexSans;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
    }

    &__description {
        font-weight: 600;
        text-align: center;
        color: var(--omnipod-grey);
        font-family: OpenSans;
        letter-spacing: -.4px;
        padding: 0 16px;
    }

    &__buttons {
        margin: 32px 0 25px;
        display: flex;
        align-items: center;
    }

    &__button {
        width: 100%;
        height: 42px;
    }
}