@import './colors.scss';
@import './fonts.scss';

.settings {
    text-align: left;
    
    .heading {
        @extend .H1BlackLeft;
        display: block;
        text-align: left;
        margin: 0;
        padding: 24px 0 12px 20px;
    }

    .body {
        padding: 8px;
        border-radius: 20px;
        display: block;
        margin: 8px auto 18px auto;
        width: calc(100vw - 42px);
        font-family: OpenSans, sans-serif;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.36px;
        text-align: left;
        color: var(--black);
        background-color: var(--white);
        padding: 0 16px;

        a {
            text-align: center;
        }
    }

    .section {
        padding: 24px 0;

        &:not(:last-child) {
            border-bottom: 1px solid var(--grey);
        }

    }

    .title {
        font-family: IBMPlexSans, sans-serif;
        font-size: 20px;
        font-weight: 600;
    }

    .description {
        color: var(--dark-grey2);
        padding-top: 1.5px;
        margin: 0;
    }

    .manageClinics {
        @extend .description;
        padding-top: 29px;
        font-weight: 600;
    }

    .noClinics {
        text-align: center;
        color: var(--dark-grey2);
        font-weight: 400;
        margin: 0 16px;
    }

    .clinicDetails {
       display: flex;
       
        .title {
            font-size: 16px;
        }

        .description {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .iconContainer {
        padding-right: 9.6px;
    }
}