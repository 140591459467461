@import './colors.scss';
@import './zIndexes.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($colorFg, 0.50);
    z-index: $modal-z-index;
}