.MuiSnackbar-root {
    &.AlertToast {
        z-index: 1;
        &.MuiSnackbar-anchorOriginTopCenter {
            top: 100px;
        }

        .MuiAlert-root {
            width: calc(100vw - 42px);
            border: 1px solid;
            font-size: 16px;
            font-weight: 600;
            border-radius: 10px;

            &.MuiAlert-standard {
                .MuiAlert-icon {
                    margin-right: 8px;
                    padding: 6px 0;

                    .MuiSvgIcon-root {
                        font-size: 28.8px;;
                    }
                }

                &Success {
                    background-color: var(--success-green-10);
                    border-color: var(--success-green-100);
                    .MuiAlert-icon path {
                        fill:  var(--success-green-100);
                    }
                }
            }
        }

        .MuiButtonBase-root {
            .MuiSvgIcon-root {
                color: $colorFg;
                width: 24px;
                height: 24px;
            }
        }
    }
}